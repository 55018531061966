/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { MouseEvent } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Button, FlexContainer, Box } from "@nordcloud/gnui";
import { SUCCESS_TEXT } from "~/constants";
import { showInfo } from "~/services/toast";

type Props = {
  code: string;
  language: string;
  wrapLongLines?: boolean;
};

export function CodeHighlighter({ code, language, wrapLongLines }: Props) {
  return (
    <Box boxStyle="lightGrey" spacing="spacing04">
      <FlexContainer alignItems="start" justifyContent="space-between">
        <SyntaxHighlighter
          language={language}
          style={{ ...github, ...{ hljs: { background: "none" } } }}
          lineProps={{
            style: { wordBreak: "break-all", fontFamily: "monospace" },
          }}
          wrapLines
          wrapLongLines={wrapLongLines}
        >
          {code}
        </SyntaxHighlighter>
        <CopyToClipboard
          text={code}
          onCopy={() => {
            showInfo(SUCCESS_TEXT.copied);
          }}
          css={{
            position: "sticky",
            top: "10px",
          }}
        >
          <Button
            icon="copy"
            size="md"
            severity="low"
            onClick={(event: MouseEvent<HTMLElement>) => event.preventDefault()}
          />
        </CopyToClipboard>
      </FlexContainer>
    </Box>
  );
}
